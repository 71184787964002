@mixin for-phone-up {
  @media (min-width: 480px) { @content; }
}
@mixin for-tablet-up {
  @media (min-width: 768px) { @content; }
}
@mixin for-laptop-up {
  @media (min-width: 992px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}

.listItem{
  .image__wrap{
    position: relative;
    width: 100%;
    aspect-ratio: 156 / 218;
    margin-bottom: 0.5rem;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }


    .itemName{
      font-weight: 600;
      font-size: 1.25rem;
    }
    .itemPrice{
      color: #337A79;
    }
  }


    /* HEADER */
  
    #header{
      // padding: 1rem 0;
      background-color: #FCFCFC;
      border-bottom: 1px solid #D9D9D9;
      height:  88px;
      display: flex;
      flex-direction: column;
      position: fixed;
      width: inherit;
      top: 0;
      z-index: 100;
      left: 0;
      width: 100%;
  
      @include for-laptop-up{
        flex-direction: row;
      }
  
      .header--main{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;


        .header--logo{


          .sandwichMenu{
            cursor: pointer;
          }

          .logo{
            max-width: 125px;
            margin-left: 5px;
    
            @include for-laptop-up{
              max-width: 180px;
            }
          }
        }
      }
  
      .navigation-desktop {
        display: none;
        height: 100%;
        width: 100%;
        
        @include for-laptop-up{
          display: block;
        }
          ul.mainNav{
          justify-content: space-evenly;
          gap: 2rem;
          display: flex;
          height: 100%;
          position: relative;
            // background-color: red;
          &>li{       
            width: 25%;
            display: flex;
            justify-content: center;
            align-items: center;
            &.shopNav{
              position: relative;
              .shopNav--expand{
                background-color: #FCFCFC;
                position: absolute;
                z-index: 1000;
                padding: 24px 100px;
                gap: 1.25rem;
                top: 100%;
                left: 0px;
                display: none;
                border-top: 1px solid #D9D9D9;
                ul{
                  display: flex;
                  flex-direction: column;
                  gap: 1rem;
                  li{
                    a{
                      text-align: left !important;
                      display: inline-block;
                      padding: 0.5rem 5rem 0.5rem 1rem;
                      width: 100%;
          
                      // padding: 10px;
                    }
                  }
                }
              }
              &:hover{
                .shopNav--expand{
                  display: flex;
                }
                &~.shopNav--expand{
                  display: flex;
                }
              }
            }

            &>a{
              width: 100%;
              height: 60%;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              border-bottom: 2px solid transparent;
              
              &.active, &:hover{
                // border-bottom: 2px solid #337A79;
                border-color: #337A79;
              }
            }
          }
    
        }
      }
      
  
      .header--icons{
        display: flex;
        gap: 12px;

        img{
          cursor: pointer;
        }

        .cart{
          display: flex;
          align-items: center;
          position: relative;
          padding-right: 0.8rem;

          .cartCount{
            position: absolute;
            right: 0;
            top: 0;
            border-radius: 50%;
            width: 14px;
            height: 14px;
            font-size: 0.6rem;
            background-color: #337A79;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            line-height: 1;
          }
        }

        .closeMenu{
          cursor: pointer;
          @include for-laptop-up{
            display: none;
          }

        }
        @include for-laptop-up{
          gap: 24px;
        }
      }
  
  
      .languageSwitch{
        display: flex;
        padding-bottom: 1rem;
        padding-top: 1rem;
        p{
          line-height: 1;
          cursor: pointer;
          color: #757575;

          &.active{
            color: #1F1D1D;
          }
        }
        .en{
          padding-right: 6px;
          border-right: 1px solid #757575;
        }
        .fr{
          padding-left: 6px;
        }
      }
  
      .languageSwitch--laptop{
        padding-bottom: 1rem;
        padding-top: 1rem;
        p{
          line-height: 1;
          cursor: pointer;
          color: #757575;
          &.active{
            color: #1F1D1D;
          }
        }
        .en{
          padding-right: 6px;
          border-right: 1px solid #757575;
        }
        .fr{
          padding-left: 6px;
        }
        display: none;
        @include for-laptop-up{
          display: flex;
        }
      }

      .mobileNav__wrap{
        position: absolute;
        width: 100%;
        top: 0;
        z-index: 400;
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s;
        background-color: #FCFCFC;
        &.active{
          max-height: 650px;
        }
        @include for-laptop-up{
          display: none;
        }

        .mobileNav__wrap__head{
          padding: 2.5rem 1rem 1rem;
          display: flex;
          justify-content: space-between;
          .logo{
            width: 125px;
          }

          .close{
            cursor: pointer;
          }
        }

        .mobileNav{
          padding: 1rem 1rem 3rem 1rem;
          justify-content: center;
          text-align: center;
          display: flex;
          // width: auto;
          gap: 3rem;
          
  

          li{
            border-bottom: 1px solid transparent;
            line-height: 2.5;
            cursor: pointer;
            &:hover{
              border-bottom: 1px solid #337A79;
            }
          }

  
          &.active{
            .mobileNavMain{
              width: 50%;
              li{
                a{
                  width: 0;
                }
              }
            }
          }

          ul{
            transition: all 0.5s ease-out;
            &.mobileNavMain{
              width: 10rem;

              a{
                white-space: nowrap;
                transition: all 0.5s ease-out;;
                width: 100%;
                display: block;
              }
            }
  
            &.mobileShop{
              max-height: 0;
              width: 0;
              overflow: hidden;
              opacity: 0;
              text-align: start;
  
              &.active{
                opacity: 1;
                max-height: 470px;
                width: calc(50% - 1rem);
              }
            }

          }
        }
      }
    }
  
    .expanderColor{
      display: flex;
      flex-direction: column;
      padding-bottom: 1rem;

      @include for-laptop-up{
        gap: 10px;
        flex-wrap: wrap;
        max-height: 150px;
      }

      .checkBox, .radio{
        display: flex;
        align-items: center;
        height: 24px;
        margin-bottom: 0.8rem;
        cursor: pointer;
        input{
          width: 17px;
          height: 17px;
          margin-bottom: 0;
          margin-right: 10px;
        }

        p{
          color: #1F1D1D;
          padding-left: 36px;
          position: relative;

          &:not(.radioMulticolor)::before{
            position: absolute;
            content: '';
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: red;
            left: 0;
          }

          &.radioRED{
            &::before{
              background-color: #BE0000;
            }
          }

          &.radioORANGE{
            &::before{
              background-color: orange;
            }
          }

          &.radioYELLOW{
            &::before{
              background-color: #FBB428;
            }
          }

          &.radioGREEN{
            &::before{
              background-color: #00804B;
            }
          }
          &.radioBLUE{
            &::before{
              background-color: #0072C0;
            }
          }
          &.radioVIOLET{
            &::before{
              background-color: #6C259C;
            }
          }
          &.radioPINK{
            &::before{
              background-color: #F02679;
            }
          }
          &.radioBLACK{
            &::before{
              background-color: #0C0B0D;
            }
          }
          &.radioWHITE{
            &::before{
              background-color: #FCFCFC;
              box-shadow: 0px 1px 1px 0px #00000017, 0px 2px 1px 0px #0000000D, 0px 3px 1px 0px #00000003, 0px 4px 1px 0px #00000000;
            }
          }
          &.radioGREY{
            &::before{
              background-color: #626163;
            }
          }
          &.radioBROWN{
            &::before{
              background-color: #614000;
            }
          }


          img{
            position: absolute;
            left: 0;
          }
        }
      }
    }

    .expanderSize{
      display: flex;
      gap: 1.5rem;
      padding-bottom: 1rem;
      .sizeBtn{
        width: 2.625rem;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #787575;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.5s;



        &:hover, &.active{
          background-color: #78757580;
        }
      }
    }


    .expanderMaterial{
      display: flex;
      flex-direction: column;

      @include for-laptop-up{
        flex-direction: row;
        gap: 5rem;
      }

      .checkBox, .radio{
        display: flex;
        align-items: center;
        height: 24px;
        margin-bottom: 0.8rem;
        cursor: pointer;
        input{
          width: 17px;
          height: 17px;
          margin-bottom: 0;
          margin-right: 10px;
        }
      }
    }

    .loader{
      content: '';
      border: 4px solid #f3f3f3;
      border-radius: 50%;
      border-top: 4px solid #3498db;
      width: 30px;
      height: 30px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
      margin: 0 auto;
    }
    @-webkit-keyframes spin {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }